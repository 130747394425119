import * as React from 'react';
import { Card, CardActions, CardContent, CardMedia, Tooltip, Box, ImageList, ImageListItem, Zoom } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Icons
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConstructionIcon from '@mui/icons-material/Construction';
import DesignServicesIcon from '@mui/icons-material/DesignServices';


export default function MediaCard(props) {
    const [height, setHeight] = React.useState("70vh")
    const [imgCols, setImgCols] = React.useState(3)
    const [width, setWidth] = React.useState("100%")
    const [isLarge, setIsLarge] = React.useState(false)
    const [checked, setChecked] = React.useState([])
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);
    const windowSize = React.useRef([window.innerWidth, window.innerHeight]);

    React.useEffect(() => {
        // Update the document title using the browser API
        console.log(props.project)
        setChecked(props.project.images.map(elem => (false)))
    }, []);

    React.useEffect(() => {
        setIsSmallScreen(windowSize.current[0] < 1200) // width >900
    }, [windowSize]);

    React.useEffect(() => {
        if (isSmallScreen) {
            setImgCols(1)
        }
    }, [isSmallScreen]);

    return (
        <>
            {"title" in props.project &&
                <Card style={{ height: height }} sx={{ overflow: "scroll", width: { width } }}>
                    <CardMedia
                        component="img"
                        sx={{ width: "100%" }}
                        image={props.project.titleImg}
                        title={props.project.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={props.project.status}>
                                    {
                                        {
                                            'verkauft': <RemoveCircleOutlineIcon style={{ margin: "0px 10px 0px 0px" }} />,
                                            'im bau': <ConstructionIcon style={{ margin: "0px 10px 0px 0px" }} />,
                                            'in planung': <DesignServicesIcon style={{ margin: "0px 10px 0px 0px" }} />
                                        }[props.project.status.toLowerCase()]
                                    }
                                </Tooltip>
                                {props.project.title}
                            </Box>
                        </Typography>
                        <div
                            dangerouslySetInnerHTML={{ __html: props.project.description }}
                        />
                        {isLarge &&
                            <>
                                <div style={{ marginTop: "1em" }} />
                                <Box style={{ "display": "flex" }}>
                                    <ImageList sx={{ width: "100%", height: 600 }} cols={imgCols ? imgCols : 3} gap={8}>
                                        {props.project.images.map((item, idx) => {
                                            if (checked[idx] === true) {
                                                return (
                                                    <ImageListItem key={"img-" + idx}>
                                                        <Zoom in={checked[idx]} appear={false}>
                                                            <img
                                                                onClick={(e) => setChecked(checked.map((elem, i) => { return false }))}
                                                                src={`${item}?w=161&fit=crop&auto=format`}
                                                                srcSet={`${item}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                                                alt={"img-" + idx}
                                                                loading="lazy"
                                                            />
                                                        </Zoom>
                                                    </ImageListItem>
                                                )
                                            } else {
                                                return (
                                                    <ImageListItem key={"img-" + idx}>
                                                        <img
                                                            onClick={(e) => setChecked(checked.map((elem, i) => { return i === idx ? true : false; }))}
                                                            src={`${item}?w=161&fit=crop&auto=format`}
                                                            srcSet={`${item}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={"img-" + idx}
                                                            loading="lazy"
                                                        />
                                                    </ImageListItem>
                                                )
                                            }
                                        })}
                                    </ImageList>
                                </Box>
                            </>
                        }
                    </CardContent>
                    <CardActions>
                        {!isLarge && props.project.images.length > 0 &&
                            <Button size="small" onClick={() => { setHeight("100%"); props.setCardLG(props.cardLG.map((elem, i) => { return i === props.idx ? 12 : elem })); setIsLarge(true) }}>
                                Zeige Galerie
                            </Button>
                        }
                        {isLarge &&
                            <Button size="small" onClick={() => { setHeight("70vh"); props.setCardLG(props.cardLG.map((elem, i) => { return i === props.idx ? 6 : elem })); setIsLarge(false) }}>
                                Verstecke Galerie
                            </Button>
                        }
                    </CardActions>
                </Card>
            }
        </>
    );
}